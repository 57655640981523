.Dashboard {
    padding-top: 71px;
    padding-left: 15px;
    padding-right: 15px;
    font-size: 14px!important;
}

.Dashboard .card-deck{
    padding-bottom: 30px;
}

.Dashboard .sm{
    border-left: .25rem solid #008dde;
}

.Dashboard .sm-card{
    padding: .5rem .5rem;
}

.Dashboard .list-group{
    cursor: default;
}

@media (max-width: 1024px) { 
    .newLineOnIpad {
        display: block;
    }

    .paddingOnIpad {
        padding-bottom: 30px;
    }
}