body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.alert-danger {
  margin: 0 20px 0 20px;
}

.btn-success.disabled, .btn-success:disabled {
  background-color: #3D9970;
  border-color: #3D9970;
}

.btn.disabled, .btn:disabled {
  opacity: 1;
}

.bg-success {
  background-color: #3D9970!important;
}

.btn {
  font-size: 13px!important;
}

.input-group-sm>.custom-select, .input-group-sm>.form-control, .input-group-sm>.input-group-append>.btn, .input-group-sm>.input-group-append>.input-group-text, .input-group-sm>.input-group-prepend>.btn, .input-group-sm>.input-group-prepend>.input-group-text {
  font-size: 13px!important;
}