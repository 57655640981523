.Login {  
    margin: 0 auto;
    margin-top: 70px;
    max-width: 360px;
    padding: 40px 40px;
}

.Login-Title {
    margin-bottom: 15px;
}

.Login-Input {
    font-family: inherit;
    font-size: 14px;
    height: 50px;
    outline: none;
    width: 280px;
}

.Login-Button {
    height: 50px;
    width: 280px;
}

.Login-Alert   {
    font-size: 14px;
    border: none;
    width: 280px;
    margin: 0;
    margin-bottom: 15px;
} 