.logs-card-textarea {
    height: 400px;
    max-height: 400px;
    overflow-y: scroll;
    background-color: #e9ecef;
    font-size: 14px;
    color: #495057;
    padding: .375rem .75rem;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    opacity: 1;
    cursor: default;
}

.logs-card-alert {
    font-size: 0.85rem;
    padding: .375rem .75rem;
}
