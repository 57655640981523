.recharts-surface{
    font-size: 12px;
}

.custom-tooltip{
    font-size: 12px;
}

.custom-tooltip .label {
    margin-bottom: 0px ;
}
