.Stock {
    padding-top: 70px;
    padding-left: 10px;
    padding-right: 10px;
}

.Stock .nav {
    margin-left: 10px;
    padding-bottom: 5px;
}


