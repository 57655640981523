.Stocks-Table{
    font-size: 12px;
    display: table;
    /* required for table-layout to be used (not normally necessary; included for completeness) */
    table-layout: auto;
    /* this keeps your columns with fixed with exactly the right width */
    width: 100%;
    /* table must have width set for fixed layout to work as expected */
    height: 100%;
    cursor: default;
}

.Stocks-Table tbody{
    text-align: center;
}

.Stocks-Table thead{
    position: fixed;
    top: 56px;
    left: 0;
    right: 0;
    width: 100%;
    background: #fff;
    table-layout: fixed;
    display: table;
    text-align: center;
}
